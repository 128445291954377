// addViewportTrackerItems.js
'use strict';
import { SET_OPERATION_DATA } from '../ActionTypes.js';
import getOperationData from '../selector/getOperationData.js';

/**
 * Add viewport tracker items
 * @kind action
 * @param {string} {id} - item id.
 * @param {string} {itemIndexInCategory} - item index in category.
 * @param {string} {categoryId} - category.
 * @return {Promise} Action promise.
 */
const addViewportTrackerItems =
  ({ id, itemIndexInCategory, categoryId } = {}) =>
  async (dispatch, getState) => {
    const currentImpressionFeeds =
      getOperationData(
        getState(),
        ['viewportItemTrackingPayload'],
        'impression.feed_names'
      ) || [];
    const currentImpressionItems =
      getOperationData(
        getState(),
        ['viewportItemTrackingPayload'],
        `impression.feed.${categoryId}`
      ) || [];
    return dispatch({
      type: SET_OPERATION_DATA,
      payload: {
        selectPath: ['viewportItemTrackingPayload'],
        data: {
          ...(getOperationData(getState(), ['viewportItemTrackingPayload']) ||
            {}),
          ['impression.feed_names']: [
            ...new Set([...currentImpressionFeeds, categoryId]),
          ],
          [`impression.feed.${categoryId}`]: [
            ...new Set([
              ...currentImpressionItems,
              `${itemIndexInCategory}::${id}`,
            ]),
          ],
        },
      },
    });
  };

export default addViewportTrackerItems;

// UserAvatarWithStatus.js
'use strict';
import UserAvatarWithStatus from '../component/UserAvatarWithStatus.jsx';

import { connect } from 'react-redux';
import getUserData from '../selector/getUserData.js';
import getLiveStreamData from '../selector/getLiveStreamData.js';
import getUserLatestMessage from '../selector/getUserLatestMessage.js';
import getMeData from '../selector/getMeData.js';
import addModal from '../action/addModal.js';
import setProfileSelectedImage from '../action/setProfileSelectedImage.js';
import { getUserPicture, MediaAssetFormat } from '../resource/getMediaAsset.js';

const mapStateToProps = (state, { id }) => {
  const meId = getMeData(state, 'id');
  return {
    meId,
    username: getUserData(state, id, 'username'),
    isOnline: getUserData(state, id, 'isOnline'),
    isLiveStreaming: getLiveStreamData(state, [id], 'isLiveStreaming'),
    isPrivateLiveStreamStarted: getLiveStreamData(
      state,
      [id],
      'isPrivateLiveStreamStarted'
    ),
    latestMessageId: getUserLatestMessage(state, id, 'id'),
    latestMessageType: getUserLatestMessage(state, id, 'type'),
    fullSizeAvatarImageSource: getUserPicture({
      userId: id,
      meId,
      size: 2048,
      format: MediaAssetFormat.JPG,
    }).href,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setProfileSelectedImage: ({
      imageSrc,
      loadingSrc,
      defaultSrc,
      isAvatar,
      userId,
    }) =>
      dispatch(
        setProfileSelectedImage({
          imageSrc,
          loadingSrc,
          defaultSrc,
          isAvatar,
          userId,
        })
      ),
    openModal: id => dispatch(addModal({ id })),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAvatarWithStatus);

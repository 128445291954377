// withViewportItemTracker.js
'use strict';
import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Observer from '@researchgate/react-intersection-observer';

import {
  IMPRESSION_TRACKING_VISIBILITY_RATIO,
  IMPRESSION_TRACKING_DURATION_SECONDS,
} from '../RemoteConfigKeys.js';

import { NULL_FUNCTION } from '../resource/defaults.js';
import {
  CONFIG_PRIORITY_PRESENCE_CLIENT,
  CONFIG_PRIORITY_PRESENCE_USER,
} from '../resource/configPriority.js';

import addViewportTrackerItems from '../action/addViewportTrackerItems.js';

import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getMeData from '../selector/getMeData.js';
import getIsRemoteConfigMerged from '../selector/getIsRemoteConfigMerged.js';

import useUnmount from '../hook/useUnmount.js';

/**
 * With viewport item tracker
 * @param {ReactComponent} BaseComponent - React component.
 */
const withViewportItemTracker = BaseComponent => {
  const ViewportItemTracker = ({
    visibilityRatio = 1,
    durationSeconds = 0,
    addViewportTrackerItems = NULL_FUNCTION,
    isRemoteConfigMerged = false,
    ...restProps
  } = {}) => {
    const { categoryId, id, itemIndexInCategory } = restProps;
    const timeoutRef = useRef(null);
    const clearTimer = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
    const handleChange = useCallback(
      ({ isIntersecting }, unobserve) => {
        clearTimer();
        if (isIntersecting) {
          timeoutRef.current = setTimeout(() => {
            unobserve();
            addViewportTrackerItems({
              categoryId,
              id,
              itemIndexInCategory,
            });
          }, durationSeconds * 1000);
        }
      },
      [
        addViewportTrackerItems,
        categoryId,
        durationSeconds,
        id,
        itemIndexInCategory,
      ]
    );
    useUnmount(() => {
      clearTimer();
    });
    if (
      !isRemoteConfigMerged ||
      !categoryId ||
      !id ||
      itemIndexInCategory == null
    ) {
      return <BaseComponent {...restProps} />;
    }
    return (
      <Observer onChange={handleChange} threshold={visibilityRatio}>
        <BaseComponent {...restProps} />
      </Observer>
    );
  };
  ViewportItemTracker.propTypes = {
    categoryId: PropTypes.string,
    id: PropTypes.string,
    itemIndexInCategory: PropTypes.number,
    visibilityRatio: PropTypes.number,
    durationSeconds: PropTypes.number,
    isRemoteConfigMerged: PropTypes.bool,
    addViewportTrackerItems: PropTypes.func,
  };
  const mapStateToProps = state => {
    const refreshToken = getMeData(state, 'refreshToken');
    const isRemoteConfigMerged = refreshToken
      ? getIsRemoteConfigMerged(state, CONFIG_PRIORITY_PRESENCE_USER)
      : getIsRemoteConfigMerged(state, CONFIG_PRIORITY_PRESENCE_CLIENT);

    return {
      visibilityRatio: getRemoteConfigData(
        state,
        IMPRESSION_TRACKING_VISIBILITY_RATIO
      ),
      durationSeconds: getRemoteConfigData(
        state,
        IMPRESSION_TRACKING_DURATION_SECONDS
      ),
      isRemoteConfigMerged,
    };
  };
  const mapDispatchToProps = dispatch => {
    return {
      addViewportTrackerItems: payload =>
        dispatch(addViewportTrackerItems(payload)),
    };
  };
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(React.memo(ViewportItemTracker));
};

export default withViewportItemTracker;

// setProfileSelectedImage.js
'use strict';
import { SET_OPERATION_DATA } from '../ActionTypes.js';

/**
 * Set profile selected image
 * @kind action
 * @param {string} {imageSrc} - selected image source.
 * @param {string} {loadingSrc} - loading image source.
 * @param {string} {defaultSrc} - default image source.
 * @param {boolean} {isAvatar} - is avatar.
 * @param {string} {userId} - user id.
 * @return {Promise} Action promise.
 */
const setProfileSelectedImage =
  ({ imageSrc, loadingSrc, defaultSrc, isAvatar, userId }) =>
  async dispatch => {
    return dispatch({
      type: SET_OPERATION_DATA,
      payload: {
        selectPath: ['profileSelectedImage'],
        data: { imageSrc, loadingSrc, defaultSrc, isAvatar, userId },
      },
    });
  };

export default setProfileSelectedImage;

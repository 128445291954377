// useUnmount.js
import { useEffect, useRef } from 'react';

/**
 * useUnmount() is a custom React hook that executes a callback function
 * when the component is unmounted.
 *
 * @param {Function} fn - The callback function to be executed on unmount.
 *
 * @example
 * // Usage in a functional component
 * const MyComponent = () => {
 *   useUnmount(() => {
 *     // Cleanup or any actions to be performed on unmount
 *     console.log("Component unmounted!");
 *   });
 *
 *   // Component rendering logic
 *   return <div>My Component</div>;
 * };
 */
const useUnmount = fn => {
  /**
   * A ref that holds the latest callback function.
   * This is necessary to ensure the correct callback is invoked
   * even if the component is unmounted before the effect cleanup.
   * @type {React.MutableRefObject<Function>}
   */
  const fnRef = useRef(fn);

  // Update the ref with the latest callback function.
  fnRef.current = fn;

  // Set up an effect to invoke the callback on unmount.
  useEffect(() => () => fnRef.current(), []);
};

export default useUnmount;

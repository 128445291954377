// getUserLatestMessage.js
'use strict';
import { Collection } from 'immutable';
import createCachedSelector from '../resource/createCachedSelector.js';

/**
 * Select user latest message by
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} id - user id.
 * @param {string} dataKey - select data key.
 * @return {any} The selected user data.
 */
const getUserLatestMessage = createCachedSelector(
  (state, id) => state.getIn(['users', id, 'latestMessage']),
  (state, id, dataKey) => dataKey,
  (latestMessage, dataKey) => {
    let result = undefined;
    if (latestMessage) {
      result = latestMessage.get(dataKey);
    }

    return result instanceof Collection ? result.toJS() : result;
  }
)((state, id, dataKey) => `${id}:${dataKey}`);

export default getUserLatestMessage;
